<template>
  <NLayout class="h-full">
    <div class="fixed left-1 top-1 z-50 flex gap-2">
      <!-- background class overrides are to fight against auto-renderer styles bleeding out -->
      <NButton
        v-show="menuButtonIsVisible"
        size="large"
        class="!bg-[var(--n-color)] active:!bg-[var(--n-color-pressed)]"
        @click="menuIsVisible = true"
      >
        <template #icon>
          <NIcon>
            <VtIcon name="bars" />
          </NIcon>
        </template>
        Menu
      </NButton>

      <NButton
        v-show="false"
        type="info"
        primary
        size="large"
        class="!bg-[var(--n-color)] active:!bg-[var(--n-color-pressed)]"
        tag="a"
        target="_blank"
        rel="noopener noreferrer"
        :href="linkToStudio"
      >
        <template #icon>
          <NIcon>
            <VtIcon name="pencil" />
          </NIcon>
        </template>
        Edit in Studio
      </NButton>

      <NDrawer v-model:show="menuIsVisible" placement="left" :width="16 * 30">
        <NDrawerContent closable>
          <template #header>
            <NuxtLink to="/" class="no-underline">
              <div>Visiontree framework</div>
              <NText depth="3" class="text-xs">Examples</NText>
            </NuxtLink>
          </template>
          <NMenu
            :options="menuOptions"
            :root-indent="0"
            :render-label="renderMenuOptionLabel"
            :value="$route.path"
            @update:value="menuIsVisible = false"
          />
          <template #footer>
            <NFlex justify="end">
              <NButton secondary @click="clearCache">Clear cache</NButton>
              <NButton secondary @click="disableMenu">
                Disable menu button
              </NButton>
            </NFlex>
          </template>
        </NDrawerContent>
      </NDrawer>
    </div>

    <NLayout>
      <NuxtPage />
    </NLayout>
  </NLayout>
</template>

<script setup lang="ts">
import { h, ref, computed, watch } from 'vue'

import set from 'lodash/set'
import {
  NLayout,
  NDrawer,
  NDrawerContent,
  NPerformantEllipsis,
  NText,
  NMenu,
  NFlex,
  NIcon,
  NButton,
  type MenuOption,
} from 'naive-ui'

import { NuxtPage, NuxtLink } from '#components'
import { useRouter, useRoute, useRuntimeConfig } from '#imports'
import type { RouteRecordNormalized } from '#vue-router'

import { VtIcon } from '@visiontree/vue-ui'

const router = useRouter()
const route = useRoute()
const runtimeConfig = useRuntimeConfig()


// THIS IS A HACK TO MAKE THE PAGE LOAD JUST OUR EXAMPLE
router.replace('/@visiontree/mfx-auto-renderer/plugins/autorenderer-plugin-example');


const menuIsVisible = ref(route.name === 'index')
const menuButtonIsVisible = ref(true)
type MenuOptionWithMaybeRoute = MenuOption & {
  to?: string
}
const renderMenuOptionLabel = (option: MenuOptionWithMaybeRoute) => {
  if ('to' in option) {
    return h(NPerformantEllipsis, null, {
      default: () =>
        h(
          NuxtLink,
          { to: option.to },
          {
            default: () => option.label,
          }
        ),
    })
  }
  return h(NPerformantEllipsis, null, {
    default: () => String(option.label),
  })
}
interface MenuItemRecord {
  children?: Record<string, MenuItemRecord>
  route?: RouteRecordNormalized
}
const recordToMenuItem = (
  record: MenuItemRecord,
  key: string
): MenuOptionWithMaybeRoute[] => {
  if (record.route) {
    const routeKey = String(record.route.path)
    return [
      {
        label:
          record.route.meta.title ||
          String(record.route.path).split('/').at(-1),
        key: routeKey,
        to: !record.children ? record.route.path : undefined,
        children: record.children
          ? recordToMenuItem(record.children, routeKey)
          : undefined,
      },
    ]
  }
  if (record.children) {
    return [
      {
        label: key.split('/').at(-1),
        key: key,
        children: recordToMenuItem(record.children, key),
      },
    ]
  }
  return (Object.entries(record) as [string, MenuItemRecord][]).flatMap(
    ([routeKey, child]) => {
      return recordToMenuItem(child, [key, routeKey].filter(Boolean).join('/'))
    }
  )
}
const menuOptions = computed(() => {
  const routes = router.getRoutes().filter((route) => {
    return route.path !== '/'
  })
  const output: Record<string, RouteRecordNormalized> = {}
  for (const route of routes) {
    const path = route.path
      .replace(/^\/(@visiontree\/)?/, '')
      .replaceAll('/', '.children.')
      .concat('.route')
    set(output, path, route)
  }
  return [
    {
      label: 'Examples',
      type: 'group',
      children: recordToMenuItem(output, '').map((item) => {
        return { ...item, label: `@visiontree/${String(item.label)}` }
      }),
    },
  ]
})

const isEmbedded = computed(() => {
  return window !== window.top
})

const isEditable = computed(() => {
  return String(route.name).includes('mfx-auto-renderer')
})

const clearCache = () => {
  localStorage.removeItem('questionnaireResponse')
  menuIsVisible.value = false
  window.location.reload()
}

const disableMenu = () => {
  menuButtonIsVisible.value = false
  menuIsVisible.value = false
}

const linkToStudio = computed(() => {
  const srcUrl = `${window.location.origin}${route.fullPath}`
  return `${
    runtimeConfig.public.studioUrl
  }/resources/import?type=iframe&src=${encodeURIComponent(srcUrl)}`
})

watch(
  isEmbedded,
  (current) => {
    if (current) {
      disableMenu()
    }
  },
  { immediate: true }
)

disableMenu();
</script>
